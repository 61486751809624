<template>
  <HeaderRdsr />
  <MainRdsr />
  <ContactsRdsr></ContactsRdsr>
  <FooterRdsr />
</template>

<script>
import ContactsRdsr from './components/ContactsRdsr.vue';
import HeaderRdsr from './components/HeaderRdsr.vue';
import MainRdsr from './components/MainRdsr.vue';
import FooterRdsr from './components/FooterRdsr.vue';
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'


export default {
  name: 'App',
  setup() {
    const siteData = reactive({
      title: `Digitalwish`,
      description: `Portugal, Web Development, Web Design, Branding and IT Consulting`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })
  },
  components: {
    MainRdsr,
    HeaderRdsr,
    ContactsRdsr,
    FooterRdsr,

}

}
</script>





<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
