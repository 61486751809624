<template>
    

    <!--FOOTER-->
    <MDBFooter bg="dark" :text="['center', 'white']">
        <!-- Grid container -->
        <MDBContainer class="p-4 pb-0">
          <!-- Section: Social media -->
          <section>
    
          </section>
          <!-- Section: Social media -->
        </MDBContainer>
        <!-- Grid container -->
        <!-- Copyright -->
        <div
          class="text-center p-3"
          style="background-color: rgba(0, 0, 0, 0.2)"
        >
          © 2024 Copyright:
          <a class="text-white"
            >Digitalwish</a
          >
        </div>
        <!-- Copyright -->
      </MDBFooter>
    </template>
    
    
    <script >
      import {
        MDBFooter,
        MDBContainer,
        MDBRow,
        MDBCol,
        MDBIcon

      }
      from 'mdb-vue-ui-kit';
      export default {
        MDBFooter,
        MDBContainer,
        MDBRow,
        MDBCol,
        MDBIcon

      }
    </script>