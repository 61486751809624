<template>
    <MDBNavbar  expand="lg" light  container="md">
      
      <MDBNavbarToggler
        @click="collapse1 = !collapse1"
        target="#navbarSupportedContent"
      ></MDBNavbarToggler>
      <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
        <MDBNavbarNav class="mb-2 mb-lg-0">
          <MDBNavbarItem to="/">
            {{$t('HOME')}}
          </MDBNavbarItem>
          <MDBDropdown text="center" class="nav-item" v-model="dropdown1">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown1 = !dropdown1"
              >DIGITALWISH</MDBDropdownToggle
            >
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton" >
              <MDBDropdownItem target="_blank" href="https://linktr.ee/digitalwish">Linktr.ee</MDBDropdownItem>
              <MDBDropdownItem target="_blank" href="https://github.com/digitalwish">Github</MDBDropdownItem>
              <MDBDropdownItem target="_blank" href="https://digitalwish.github.io">Github.io</MDBDropdownItem>
              <MDBDropdownItem target="_blank" href="https://www.linkedin.com/company/digitalwish/?viewAsMember=true">Linkedin</MDBDropdownItem>
              <MDBDropdownItem target="_blank" href="https://www.facebook.com/digitalwish.pt">Facebook</MDBDropdownItem>
             

            </MDBDropdownMenu>
          </MDBDropdown>

          <MDBDropdown class="nav-item" v-model="dropdown5">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown5 = !dropdown5"
              >CORE TEAM</MDBDropdownToggle
            >
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
              
              <MDBDropdownItem href="https://ricardodsr.github.io">Ricardo Rouco</MDBDropdownItem>
              <MDBDropdownItem href="https://www.linkedin.com/in/patríciasrgomes/">Patricia Gomes</MDBDropdownItem>
              <MDBDropdownItem href="https://www.linkedin.com/in/francisco-cunha-11423057/">Francisco Cunha</MDBDropdownItem>


            </MDBDropdownMenu>
          </MDBDropdown>

        </MDBNavbarNav>
        <!-- Lang form -->
         <!-- Icon dropdown -->
        <div class="locale-changer">
          <select  class="nav-item" v-model="$i18n.locale">
            <option flag="united-kingdom" class="m-0" value="en"><i class="flag-poland flag"></i>English</option>
            <option value="pt">Português</option>
            <option value="es">Español</option>
            
          </select>
       </div>
        <!-- Lang form -->
         <!-- Icon dropdown -->
      </MDBCollapse>
    </MDBNavbar>
  </template>
  
  <script>
  import {
      
      MDBNavbar,
      MDBNavbarToggler,
      MDBDropdown,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,
      MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
      
      
     
    } from 'mdb-vue-ui-kit';
    import { ref } from 'vue';
    
    export default {
      components: {
        
      MDBNavbar,
      MDBNavbarToggler,
      MDBDropdown,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,
      MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
      
      },
      setup() {
        const collapse1 = ref(false);
        const dropdown1 = ref(false);
        const dropdown5 = ref(false);
       
        return {
          collapse1,
          dropdown1,
          dropdown5
        }
      }
    };
    
    
  </script>

  
  