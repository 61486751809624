<template>

<img src="../assets/DWBOOK.jpg" class="img-fluid" alt="..." max-width="70%"
/>
 
      <!-- Background image -->
    
  <!-- About us -->
  <section id= "about">
  <MDBContainer class="my-5">
    <MDBRow class="row">
      <MDBCol md="7">
        <MDBCard text="center" style="height:100%;">
    <MDBCardBody  >
      <MDBCardTitle class="fs-1">DigitalWish</MDBCardTitle>
      <MDBCardText class="fs-4">
        {{$t('About')}}
      </MDBCardText>
     
    </MDBCardBody>
    
  </MDBCard>
  </MDBCol>
  <!-- About us -->
  <!-- 3 CARDS-->
      <MDBCol md="5">
        <MDBCard text="center">
    <MDBCardBody>
      <MDBCardTitle class="fs-2">{{$t('Developer')}}</MDBCardTitle>
      <MDBCardText class="fs-4">
        {{$t('Developer1')}}
      </MDBCardText>
    </MDBCardBody>  
  </MDBCard>

  <MDBCard text="center" class="my-5">
    <MDBCardBody>
      <MDBCardTitle class="fs-2">{{$t('Paradigms')}}</MDBCardTitle>
      <MDBCardText class="fs-5">
        {{$t('Paradigms1')}}
      </MDBCardText>
    </MDBCardBody>  
  </MDBCard>

  <MDBCard text="center">
    <MDBCardBody>
      <MDBCardTitle class="fs-2">{{$t('Social')}}</MDBCardTitle>
      <MDBCardText class="fs-5">
        {{$t('Social1')}}
      </MDBCardText>
    </MDBCardBody>  
  </MDBCard>

  
          
        <!-- END 3 CARDS--> 
        
  </MDBCol>
    </MDBRow>
    <MDBContainer class="p-2">
 <div class="ratio ratio-16x9">
                        <video controls autoplay>
          <source src="../assets/Presentation.mp4" type="video/mp4">
          
      </video>
    </div>
    </MDBContainer>
  </MDBContainer>
  </section>
  <!-- Modules -->
   
  <!--Services-->
  <section id= "services">
  
   <MDBContainer>
    <h1 class="text-center my-3" >{{ $t('Certs') }}</h1>
    <div class="text-center my-5" >
    </div>
  
  <MDBCardGroup >
      <MDBCol sm="6">
    <MDBCard text="center" class="h-100"  >
   <MDBCardBody>
          <MDBCardTitle>{{ $t('WebDev') }}</MDBCardTitle>
          <MDBCardText>
            {{ $t('WebDev1') }}
          </MDBCardText>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody>
     </MDBCard>
     </MDBCol>

<MDBCol sm="6">
  <MDBCard text="center" class="h-100"  > 
    <MDBCardBody>
          <MDBCardTitle>{{ $t('Design') }}</MDBCardTitle>
          <MDBCardText>
            {{ $t('Design1') }}
          </MDBCardText>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody> 
  </MDBCard>
</MDBCol>
<!--Services line 2 -->
  <MDBCol sm="6" class="my-5">
    <MDBCard text="center" class="h-100"  >
  
      
      <MDBCardBody>
          <MDBCardTitle>{{ $t('Digital') }}</MDBCardTitle>
          <MDBCardText>
            {{ $t('Digital1') }}
          </MDBCardText>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody> 
      
  </MDBCard>
</MDBCol>
<MDBCol sm="6" class="my-5">
    <MDBCard text="center" class="h-100"  >
    
      <MDBCardBody>
          <MDBCardTitle>{{ $t('Branding') }}</MDBCardTitle>
          <MDBCardText>
            {{ $t('Branding1') }}
          </MDBCardText>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody> 
       
      
   
  </MDBCard>
</MDBCol>

</MDBCardGroup>

<!--Services line 3 -->

<h2 class="text-center my-3" >{{ $t('Certs1') }}</h2>
    
<MDBCardGroup >  
<MDBCol sm="6" class="my-5">
    <MDBCard text="center" class="h-100"  >
  
      
      <MDBCardBody>
          <MDBCardTitle>{{ $t('Consulting') }}</MDBCardTitle>
          <MDBCardText>
            {{ $t('Consulting1') }}
          </MDBCardText>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody> 
      
  </MDBCard>
</MDBCol>
<MDBCol sm="6" class="my-5">
    <MDBCard text="center" class="h-100"  >
    
      <MDBCardBody>
          <MDBCardTitle>{{ $t('Specialized') }}</MDBCardTitle>
          <MDBCardText>
            {{ $t('Specialized1') }}
          </MDBCardText>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody> 
       
      
   
  </MDBCard>
</MDBCol>
</MDBCardGroup>

<MDBCard>
    <MDBCardBody text="center">
      <MDBCard text="center" class="h-100"   >
        <MDBBtn tag="a" @click="scrollToAnchorPoint('contact')" color="primary">{{ $t('Contact') }}</MDBBtn>
    </MDBCard>
    </MDBCardBody>
  </MDBCard>





</MDBContainer>
  </section>

    <!--Certs-->
<MDBContainer class="p-3">
    <div class="ratio ratio-16x9">
                        <video controls>
          <source src="../assets/Digitalwish.pt.mp4" type="video/mp4" img="../assets/DWS.png ">
          
      </video>
    </div>
  </MDBContainer> 
<!-- Teams -->
<MDBContainer class="my-5">
    <h2 class="text-center my-3" >{{ $t('Team') }}</h2>
    <div class="text-center my-5" >
    </div>
</MDBContainer>

<MDBContainer class="my-5">
  <MDBRow>
    <MDBCol sm="4">
      <MDBCard text="center" >
        <MDBCardBody>
          <div class="text-center">
      <div class="text-center my-2" >
          <img
          src="../assets/ticha.png"
          height="350"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
          <MDBCardTitle>Patrícia Gomes</MDBCardTitle>
          <MDBCardText>
            MARKETEER AND MARKET SPECIALIST
          </MDBCardText>
          <MDBBtn tag="a" href="https://www.linkedin.com/in/patríciasrgomes/" target="_blank" color="primary">Linkedin</MDBBtn>
          <MDBBtn tag="a" href="mailto:patriciaisrgomes@gmail.com" color="primary">Mail</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol sm="4">
      <MDBCard text="center">
        <MDBCardBody>
          <div class="text-center">
      <div class="text-center my-2" >
          <img
          src="../assets/rouco.jpeg"
          height="350"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
          <MDBCardTitle>Ricardo Rouco</MDBCardTitle>
          <MDBCardText>
            FOUNDER AND SENIOR DEVELOPER 
          </MDBCardText>
          <MDBBtn tag="a" href="https://www.linkedin.com/in/ricardo-rouco-57420b37/" target="_blank" color="primary">Linkedin</MDBBtn>
          <MDBBtn tag="a" href="https://ricardodsr.github.io/" target="_blank" color="primary">Webpage</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol sm="4">
      <MDBCard text="center">
        <MDBCardBody>
          <div class="text-center">
      <div class="text-center my-2" >
          <img
          src="../assets/trinco.jpeg"
          height="350"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
          <MDBCardTitle>Francisco Cunha</MDBCardTitle>
          <MDBCardText>
            SENIOR SOFTWARE ENGINEER
          </MDBCardText>
          <MDBBtn tag="a" href="https://www.linkedin.com/in/francisco-cunha-11423057/" target="_blank" color="primary">Linkedin</MDBBtn>
          <MDBBtn tag="a" href="mailto:thefranciscocunha@gmail.com" color="primary">mail</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>   
</MDBContainer>

<MDBContainer class="my-5">
    <h2 class="text-center my-3" >{{ $t('Portfolio') }}</h2>
    <div class="text-center my-5" >
    </div>
</MDBContainer>

<MDBContainer class="my-6">
  <MDBRow :cols="['1','md-2']" class="g-4">
    <MDBCol>
      <MDBCard>
        <div class="text-center">
      <div class="text-center my-2" >
          <img class="img-fluid"
          src="https://raw.githubusercontent.com/Digitalwish/PortVue/main/facijoyas-1.jpg"
          height="400"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
        <MDBCardBody text="center">
          <MDBCardTitle>Facy Joyas</MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol>
      <MDBCard>
        <div class="text-center">
      <div class="text-center my-2" >
          <img class="img-fluid"
          src="https://raw.githubusercontent.com/Digitalwish/PortVue/main/Mockup-home-maldes.jpg"
          height="400"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
        <MDBCardBody text="center">
          <MDBCardTitle>Maldes</MDBCardTitle>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol>
      <MDBCard>
        <div class="text-center">
      <div class="text-center my-2" >
          <img class="img-fluid"
          src="https://raw.githubusercontent.com/Digitalwish/PortVue/main/Be-at.jpg"
          height="400"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
        <MDBCardBody text="center">
          <MDBCardTitle>Be-At</MDBCardTitle>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol>
      <MDBCard>
        <div class="text-center">
      <div class="text-center my-2" >
          <img class="img-fluid"
          src="https://raw.githubusercontent.com/Digitalwish/PortVue/main/Rj-Paris.jpg"
          height="400"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
        <MDBCardBody text="center">
          <MDBCardTitle>RJ Paris</MDBCardTitle>
          <MDBCardText>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol>
      <MDBCard>
        <div class="text-center">
      <div class="text-center my-2" >
          <img class="img-fluid"
          src="https://raw.githubusercontent.com/Digitalwish/PortVue/main/timeoutcard.jpg"
          height="400"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
        <MDBCardBody text="center">
          <MDBCardTitle>Timeout</MDBCardTitle>
          <MDBCardText>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol>
      <MDBCard>
        <div class="text-center">
      <div class="text-center my-2" >
          <img class="img-fluid"
          src="https://raw.githubusercontent.com/Digitalwish/PortVue/main/Ofac.jpg"
          height="400"
          alt=""
          loading="lazy"
        />
        </div>
    </div>
        <MDBCardBody text="center">
          <MDBCardTitle>OFAC</MDBCardTitle>
          <MDBCardText>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
  
  </MDBContainer>
<div ref="contact"></div>

  
  </template>
  
  
  <script>
  import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    
    MDBBtn,
    MDBCardGroup,
    
  } from 'mdb-vue-ui-kit';
  export default {
    components: {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBCardGroup,
},
methods: {
        scrollToAnchorPoint(refName) {
            const el = this.$refs[refName]
            el.scrollIntoView({ behavior: 'smooth' })

        }
  }
  }
  </script>
  
  
  
  <style>
  /* Default height for small devices */
  #intro-example {
      height: 400px;
    }
  
  /* Height for devices larger than 992px */
  @media (min-width: 992px) {
    #intro-example {
      height: 600px;
    }
  }
  </style>