<template>
  <div ref="contact"></div>
    <h2 class="text-center my-5">{{ $t('Contact') }}</h2>
      <!--Contacts-->
  <section>
  <MDBContainer class="my-5">
  <MDBRow>
    <MDBCol md="6">             
          <form role="form" method="POST" @submit.prevent="sendEmail">
      <!-- Name input -->
      <MDBInput
        type="text"
        label="Name"
        id="form4Name"
        v-model="nameMsg"
        wrapperClass="mb-4"
      />
  
      <!-- Email input -->
      <MDBInput
        type="email"
        label="Email address"
        id="form4Email"
        v-model="emailMsg"
        wrapperClass="mb-4"
      />
  
      <!-- Message input -->
      <MDBTextarea
        label="Message"
        id="form4Textarea"
        v-model="messageMsg"
        wrapperClass="mb-4"
      />
  
      <!-- Checkbox -->
      <div class="form-check d-flex justify-content-center mb-4">
        <MDBCheckbox
          label="Send me a copy of this message"
          id="form4CopyCheck"
          v-model="form4CopyCheck"
        />
      </div>
  
      <!-- Submit button -->
      <MDBInput color="primary" block class="mb-4" type="submit" :disabled="!nameMsg || !emailMsg || !messageMsg"></MDBInput>
    </form>
  </MDBCol>
  
  <MDBCol md="6">
    <div class="text-center">
        <MDBCol md="12">
        <MDBBtn target="_blank" tag="a" href="" color="primary">10:00 - 19:00 GMT</MDBBtn>
      </MDBCol>
      
        <MDBCol md="12" class="my-5">
        <MDBBtn target="_blank" tag="a" href="https://www.facebook.com/digitalwish.pt" color="primary">Facebook</MDBBtn></MDBCol>
        <MDBCol md="12" class="my-5"> 
        <MDBBtn target="_blank" tag="a" href="https://www.linkedin.com/company/digitalwish/?viewAsMember=true" color="primary">Linkedin</MDBBtn></MDBCol>
        <MDBCol md="12" class="my-5">
        <MDBBtn target="_blank" tag="a" href="https://discord.gg/2VWABca6" color="primary">Discord</MDBBtn></MDBCol>
        </div>
    
  
  </MDBCol>
  </MDBRow>
  </MDBContainer>
  </section>
  </template>
  
  
  
  <script>
   import {
      MDBInput,
      MDBCheckbox,
      MDBBtn,
      MDBTextarea,
      MDBCol,
      MDBRow,
      MDBContainer,
    } from "mdb-vue-ui-kit";
    
    import axios from 'axios';
  
    
  
    export default {
      components: {
        MDBInput,
        MDBCheckbox,
        MDBBtn,
        MDBTextarea,
        MDBCol,
        MDBRow,
        MDBContainer,
      },
  data() {    
  return {      
        nameMsg: '',      
        emailMsg: '',      
        messageMsg: '',      
        loadingTxt: false,
        box:false,
        };  
  },
  methods: {    
  sendEmail() {      
  this.loadingTxt= true;  
  this.box = window.alert("Mesagem enviada com sucesso!") ;    
  axios.post('https://formspree.io/f/xwkzlzwr',
  {
     name: this.nameMsg,          
     from: this.emailMsg,          
     _subject: `${this.nameMsg} | Friendly Message from Github Page`,
     message: this.messageMsg,},
     
  )
  .then((response) => {
    console.log(response)
    this.loadingTxt= false;
    this.nameMsg = '';
    this.emailMsg = '';
    this.messageMsg = '';
    
  })
  },
  },
  };
  
  </script>