import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import { createHead } from '@vueuse/head'
import VueGtag from 'vue-gtag'
import { VueCookieNext } from 'vue-cookie-next'

const head = createHead();
const app = createApp(App);
app.use(i18n);
app.use(head); 
app.use(VueCookieNext);
app.use(VueGtag, {
  config: { id: 'G-1X6ZQJ40P0' },
  enabled: false,
});


// Will be used later
app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app');